<template>
	<section id="cta-section"
			 ref="componentRef"
			 class="flex-module container-fluid flexible-calltoaction">
		<div class="hider" />
		<div class="row">
			<div class="col-xs-12">
				<div ref="ctaOneRef"
					 class="cta-one">
					<div class="content-wrapper">
						<div ref="titleOneRef">
							<h2 class="so-expand cta-title">
								Inte<span>r</span>ested in starting a pr<span>o</span>ject?
							</h2>
						</div>
						<p class="initial-text">
							We'd love to hear more and chat about how we can elevate with design and development
						</p>

						<baseBookButton class="cta-one-button"
										classes="button--secondary colored cta-form-reveal"
										:cursor="{ 'custom': true }"
										@mouseenter="wiggletl.pause(0)"
										@mouseleave="resumeWiggleButton">
							Get in touch
						</baseBookButton>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import { useMetaStore } from '~~/stores/metaStore'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CustomEase } from 'gsap/CustomEase'
import { CustomWiggle } from 'gsap/CustomWiggle'
import { SplitText } from 'gsap/SplitText'

if (process.client) gsap.registerPlugin(ScrollTrigger, SplitText, CustomEase, CustomWiggle)

const metaStore = useMetaStore()
const { $bus } = useNuxtApp()

// form anim variables
const componentRef = ref(null)
const ctaOneRef = ref(null)
const titleOneRef = ref(null)

const formRevealed = ref(false)
const triggers = {}
let $q, wiggletl, intent

function decideLogoColor() {
	return formRevealed.value ? { primary: '#FDFDFD', secondary: '#121212' } : { primary: metaStore.activePageColors.foregroundColor, secondary: metaStore.activePageColors.backgroundColor }
}

function animColorLogo() {
	triggers.colorLogo = ScrollTrigger.create({
		id: 'ctaColorLogo',
		trigger: componentRef.value,
		start: 'top top+=75',
		end: 'bottom top+=75',
		markers: false,
		onEnter: () => {
			$bus.$emit('header-change-color', decideLogoColor())
		},
		onEnterBack: () => {
			$bus.$emit('header-change-color', decideLogoColor())
		},
		onLeave: () => {
			$bus.$emit('header-change-color', { primary: '#FDFDFD', secondary: '#121212' })
		},
		onLeaveBack: () => {
			metaStore.overCta = false
			$bus.$emit('header-change-color', { primary: '#121212', secondary: '#FDFDFD' })
		},
	})
}


function resumeWiggleButton() {
	if (typeof intent === 'number') {
		clearTimeout(intent)
	}

	intent = setTimeout(resumeWiggle, 2000)

	function resumeWiggle() {
		wiggletl.play(0)
	}

}
function initAnimWiggleButton() {
	CustomWiggle.create('funWiggle', { wiggles: 10, type: 'anticipate' })
	wiggletl = gsap.timeline({ paused: true, repeat: -1, repeatDelay: 2.5 }).to($q('.cta-one-button'), { duration: 1.75, rotation: 5, ease: 'funWiggle' })

	wiggletl.play()
}

function initAnims() {
	$q = gsap.utils.selector(componentRef.value)

	animColorLogo()
	initAnimWiggleButton()
}

onMounted(() => {
	useAnims(initAnims, triggers)
})
</script>

<style lang="scss" scoped>
.hider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transform: translateX(100%);
	will-change: transform;
	bottom: -5px;
	background-color: $black;
}

.flexible-calltoaction {
	position: relative;
	margin-top: 0;
	margin-bottom: -2px;
	background-color: var(--background-color, $white);
	color: var(--foreground-color, $black);
	transition-property: background-color;
	transition-duration: 0.5s;
	transition-timing-function: ease-in-out;
	overflow: hidden;

	@include universal-side-padding;
	padding-top: rem-calc(100);
	padding-bottom: rem-calc(100);

	@include for-devices-above(md) {
		padding-top: rem-calc(129);
		padding-bottom: rem-calc(129);
	}

	@include for-devices-above(xl) {
		padding-top: rem-calc(200);
		padding-bottom: rem-calc(200);
	}
}

.cta-one {
	width: 100%;
	display: flex;
	justify-content: space-around;

	.cta-title {
		width: 95%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;

		@include for-devices-above(sm) {
			width: 100%;
		}
	}

	.content-wrapper {
		width: 100%;
		text-align: center;

		.initial-text {
			// @include textH5;
		}
	}

	h2 {
		text-transform: none;
		text-align: center;
		font-size: rem-calc(28);
		margin: 0;

		@include for-devices-above(sm) {
			font-size: rem-calc(36);
		}

		@include for-devices-above(md) {
			font-size: rem-calc(46);
		}


		@include for-devices-above(xl) {
			font-size: rem-calc(54);
		}

		@include for-devices-above(xxl) {
			font-size: rem-calc(58);
		}
	}

	.initial-text {
		font-size: rem-calc(20);
		font-variation-settings: 'wght' 400;
		margin: 0;
		@include margin(24px);

		@include for-devices-above(sm) {
			font-size: rem-calc(24);
			@include margin(48px);
		}

		@include for-devices-above(md) {
			font-size: rem-calc(36);
			@include margin(60px);
		}

		@include for-devices-above(xl) {
			font-size: rem-calc(42);
			@include margin(75px);
		}

		@include for-devices-above(xxl) {
			font-size: rem-calc(46);
		}
	}

	.cta-one-button {
		display: inline-block;
		@include margin(48px);

		@include for-devices-above(sm) {
			@include margin(60px);
		}

		@include for-devices-above(md) {
			@include margin(72px);
		}

		@include for-devices-above(xl) {
			@include margin(100px);
		}

		@include for-devices-above(xxl) {
			@include margin(120px);
		}
	}
}

.cta-two {
	--foreground-color: #fdfdfd;
	--background-color: #121212;
	height: 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	color: $white;
	visibility: hidden;
	margin-top: 10px;

	.content-wrapper {
		width: 100%;
		// max-width: 83vw;
	}

	.form-title {
		--font-weight: 700;
		--font-width: 400;
		@include textH3;
		text-align: center;
		width: 90%;
		margin: 0;
		margin-left: auto;
		margin-right: auto;

		@include for-devices-above(md) {
			margin-bottom: rem-calc(100);
		}

		@include for-devices-above(xl) {
			font-size: rem-calc(78);
		}

		@include for-devices-above(sm) {
			width: 100%;
		}

		span {
			font-family: 'SuperStrange';
			font-variation-settings: 'wdth' var(--font-width, 1);
		}
	}

	.formstatus {
		visibility: hidden;
		@include textBody;

		.highlight-form {
			color: $colorRed;
		}

		@include for-devices-above(md) {
			margin-bottom: rem-calc(200);
		}
	}

	form {
		overflow: hidden;
		height: auto;

		.form-row,
		.form-column {
			display: flex;
			margin: 0;

			@include for-devices-above(md) {
				margin: 3em 0;
			}

			label {
				@include textBody;
				align-self: center;
				padding-right: 1em;
				padding-bottom: 5px;

				&:first-of-type {
					padding-left: 0;
				}

				@include for-devices-above(md) {
					padding-left: 1em;
				}
			}

			input {
				@include textBody;
				flex: 1;
				min-width: 10px;
				height: 3em;
				border-bottom: 4px solid $white;
				transition: border-color 0.2s ease-in-out;

				&#contact-details {
					@include for-devices-above(md) {
						margin-top: 15px;
					}
				}

				@include for-devices-below(lg) {
					border-bottom: 3px solid $white;
				}

				@include for-devices-below(md) {
					border-bottom: 2px solid $white;
				}

				&.highlighted {
					border-color: $colorRed;
				}
			}



			p {
				@include textBody;
			}


			.p-label {
				align-self: flex-start;
				margin: 0;
				margin: 1em 0.25em;
				padding-right: 1em;
				padding-bottom: 5px;

				&.vert-center {
					align-self: center;
				}
			}


			.categories,
			.contactvia-group {
				width: 100%;
				margin: 1em 0.25em;
				display: flex;
				flex-flow: row wrap;
				gap: 1em;
				padding-right: 1em;

				@include for-devices-above(md) {
					margin-top: 3em;
					gap: 1.5em;
				}
			}

			.contactvia-group {
				width: unset;
				margin: 1em 0.25em;

				label {
					width: unset;
					display: inline-block;
				}

				@include for-devices-below(md) {
					// width: 100%;
					flex-flow: row nowrap;
				}
			}

			.checkbox-label,
			.radio-label {
				padding: 0;

				input {
					position: absolute;
					opacity: 0;
				}

				input:checked+.label-text {
					background-color: $white;
					color: $black;
				}

				&:focus-within span {
					box-shadow: 0 0 0 7px var(--foreground-color, inherit);
				}

				.label-text {
					@include textSecondaryButton;
					transition-duration: 0.5s;
					transition-property: color, background-color, box-shadow;
					transition-timing-function: $easeOutQuint;
					display: inline-block;
					border-radius: 500px;
					text-decoration: none;
					color: var(--foreground-color, inherit);
					background-clip: border-box;

					// margin-bottom: rem-calc(10);
					padding: rem-calc(10 24 12 24);
					box-shadow: 0 0 0 2px var(--foreground-color, inherit);

					@include for-devices-above(sm) {
						padding: rem-calc(13 32 16 32);
						box-shadow: 0 0 0 3px var(--foreground-color, inherit);
					}

					@include for-devices-above(lg) {
						padding: rem-calc(12 32 18 32);
						box-shadow: 0 0 0 4px var(--foreground-color, inherit);
					}

					&:hover {
						box-shadow: 0 0 0 7px var(--foreground-color, inherit);
					}
				}
			}
		}

		.form-column {
			flex-direction: column;
			align-items: flex-start;

			label {
				width: 100%;
			}

			textarea {
				flex: 1;
			}
		}

		textarea {
			border-bottom: 5px solid $white;
			width: 100%;
			height: 10rem;
			display: block;
			padding-top: rem-calc(20);
			@include textBody;
		}

		.submit-button {
			align-self: center;
			margin-top: 3em;
		}

		@include for-devices-below(md) {

			label,
			input,
			.form-row {
				display: block;
				width: 100%;
			}

			label {
				padding: 1rem 0;
			}
		}
	}
}

.cta-cottom {
	margin-bottom: 0 !important;
}
</style>